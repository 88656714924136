h1 {
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
}

.container{
    display: flex;
    height: 50px;
    gap: 5px;
    justify-content: center;
}

.smallContainer{
    max-width: 33%;
}
.container2 {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 300px;
    margin: 20px auto;
}


.title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.content {
    font-size: 14px;
}


.container > * {
    flex-basis: 100px;
    border: 5px solid gray;
}

Header {
    background-color: #95a5a6;
}

.white {
    background-color: white;}
.blue{background-color: blue;}
.purple{background-color: rebeccapurple;}
.brown{background-color: saddlebrown;}
.black{background-color: black; border: 5px solid red;}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#pillar {
    display: flex;
    flex: 10;
    flex-direction: column;
    text-align: center;
    border: none;
    height: auto;
}

.footer {
    height: 60px; /* Set the height of your footer */
}

/* Container styles */
.form-container {
    position: relative;
    /* Add necessary styles for your container */
}

/* Pop-up overlay styles */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999; /* Ensure the overlay is above other content */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Sign-up form styles */
.signup-form {
    background-color: #fff;
    width: 80%;
    max-width: 600px; /* Set maximum width for the form */
    padding: 20px;
    border-radius: 8px;
    /* Add additional styling as needed */
}
